:root {
  /* p4 */
  --plt-primary-000: #effcf6;
  --plt-primary-100: #c6f7e2;
  --plt-primary-200: #8eedc7;
  --plt-primary-300: #65d6ad;
  --plt-primary-400: #3ebd93;
  --plt-primary-500: #27ab83;
  --plt-primary-600: #199473;
  --plt-primary-700: #147d64;
  --plt-primary-800: #0c6b58;
  --plt-primary-900: #014d40;

  /* Tweaked so -000 is lighter */
  --plt-neutral-000: hsl(0, 0%, 99%);
  --plt-neutral-100: hsl(0, 0%, 96%);
  --plt-neutral-200: hsl(0, 0%, 89%);
  --plt-neutral-300: hsl(0, 0%, 80%);
  --plt-neutral-400: hsl(0, 0%, 70%);
  --plt-neutral-500: hsl(0, 0%, 60%);
  --plt-neutral-600: hsl(0, 0%, 49%);
  --plt-neutral-700: hsl(0, 0%, 39%);
  --plt-neutral-800: hsl(0, 0%, 30%);
  --plt-neutral-900: hsl(0, 0%, 23%);
  --plt-neutral-A00: hsl(0, 0%, 15%);
  --plt-neutral-B00: hsl(0, 0%, 10%);

  --plt-supporting-0-000: #eaf6ff;
  --plt-supporting-0-100: #b6e0fe;
  --plt-supporting-0-200: #84c5f4;
  --plt-supporting-0-300: #62b0e8;
  --plt-supporting-0-400: #4098d7;
  --plt-supporting-0-500: #2680c2;
  --plt-supporting-0-600: #186faf;
  --plt-supporting-0-700: #0f609b;
  --plt-supporting-0-800: #0a558c;
  --plt-supporting-0-900: #003e6b;

  --plt-supporting-1-000: #eae2f8;
  --plt-supporting-1-100: #cfbcf2;
  --plt-supporting-1-200: #a081d9;
  --plt-supporting-1-300: #8662c7;
  --plt-supporting-1-400: #724bb7;
  --plt-supporting-1-500: #653cad;
  --plt-supporting-1-600: #51279b;
  --plt-supporting-1-700: #421987;
  --plt-supporting-1-800: #34126f;
  --plt-supporting-1-900: #240754;

  --plt-supporting-2-000: #fffaeb;
  --plt-supporting-2-100: #fcefc7;
  --plt-supporting-2-200: #f8e3a3;
  --plt-supporting-2-300: #f9da8b;
  --plt-supporting-2-400: #f7d070;
  --plt-supporting-2-500: #e9b949;
  --plt-supporting-2-600: #c99a2e;
  --plt-supporting-2-700: #a27c1a;
  --plt-supporting-2-800: #7c5e10;
  --plt-supporting-2-900: #513c06;

  --plt-danger-000: #ffeeee;
  --plt-danger-100: #facdcd;
  --plt-danger-200: #f29b9b;
  --plt-danger-300: #e66a6a;
  --plt-danger-400: #d64545;
  --plt-danger-500: #ba2525;
  --plt-danger-600: #a61b1b;
  --plt-danger-700: #911111;
  --plt-danger-800: #780a0a;
  --plt-danger-900: #610404;

  /* end palette */

  --ff-0: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  /* type scale */
  /* p91 */
  --fs-0: 12px;
  --fs-1: 13px;
  --fs-2: 14px;
  --fs-3: 16px;
  --fs-4: 18px;
  --fs-5: 20px;
  --fs-6: 24px;
  --fs-7: 30px;
  --fs-8: 36px;
  --fs-9: 48px;
  --fs-A: 60px;
  --fs-B: 72px;

  /* spacing */
  --sp-0: 2px;
  --sp-1: 4px;
  --sp-2: 8px;
  --sp-3: 12px;
  --sp-4: 16px;
  --sp-5: 24px;
  --sp-6: 32px;
  --sp-7: 48px;
  --sp-8: 64px;
  --sp-9: 96px;
  --sp-A: 128px;
  --sp-B: 256px;
  --sp-C: 384px;
  --sp-D: 512px;
  --sp-E: 640px;
  --sp-F: 768px;

  /* border-radius */
  --br-0: 1px;
  --br-1: 2px;
  --br-2: 4px;
  --br-3: 8px;
  --br-4: 12px;
  --br-5: 16px;

  --z-index-header: 2;
  --z-index-header-menu: 3;
  --z-index-floating-action-button: 4;
  --z-index-dialog-overlay: 5;
  --z-index-dialog-content: 6;
  --z-index-snackbar: 7;

  --app-main-width-lg: 895px;
  --app-gutter-width: var(--sp-4);
  --app-gutter-width-md: var(--sp-8);
  --app-header-height: 56px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.5;
  color: #424242;
  background-color: #fff;
}

a {
  color: #424242;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  border: none;
  cursor: pointer;
}

form {
  margin: 0;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    -webkit-transition: none !important;
    transition: none !important;
  }
  body {
    overflow: visible;
  }
  header,
  nav,
  footer,
  svg {
    display: none !important;
  }
  /* Remove sidebar nav margin-left, if any. */
  nav + * {
    padding: 0 !important;
    margin: 20px !important;
    max-width: 100% !important;
  }
}

@page {
  margin: 0;
}
