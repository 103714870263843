.ReactModal__Overlay {
  opacity: 0;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}
.ReactModal__Content--after-open {
  transition: opacity 150ms ease-in;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content--before-close {
  transition: opacity 150ms ease-in;
}
