.root {
  background-color: var(--theme-header-background-color);
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  z-index: var(--z-index-header);
}
